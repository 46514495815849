<template>
  <div class="price-tiered">
    <button class="close" @click="$emit('close')">
      <span class="sr-only">Close Tiered Prices</span>
    </button>
    <ul>
      <li>
        <span class="price">
          <Price :amt="Number(product.price_excl_tax)" />
        </span>
        <span class="count">
          1-{{ product.discount_threshold - 1 }} bottles
          {{ itemSizeStr }}
        </span>
        <span
          v-show="
            product.show_retail_price &&
            !isTinyDiscount(product, product.price_excl_tax)
          "
          class="savings"
        >
          {{ percentageDiscount(product, product.price_excl_tax) }}% off
        </span>
      </li>
      <li v-if="product.discount_price_excl_tax">
        <span class="price">
          <Price :amt="Number(product.discount_price_excl_tax)" />
        </span>
        <span v-if="!product.deeper_discount_price_excl_tax" class="count">
          {{ product.discount_threshold }}+ bottles
          {{ itemSizeStr }}
        </span>
        <span v-else class="count">
          {{ product.discount_threshold }}-{{
            product.deeper_discount_threshold - 1
          }}
          bottles {{ itemSizeStr }}
        </span>
        <span
          v-show="
            product.show_retail_price &&
            !isTinyDiscount(product, product.discount_price_excl_tax)
          "
          class="savings"
        >
          {{ percentageDiscount(product, product.discount_price_excl_tax) }}%
          off
        </span>
      </li>

      <li v-if="product.deeper_discount_price_excl_tax">
        <span class="price">
          <Price :amt="Number(product.deeper_discount_price_excl_tax)" />
        </span>
        <span class="count">
          {{ product.deeper_discount_threshold }}+ bottles
          {{ itemSizeStr }}
        </span>
        <span
          v-show="
            product.show_retail_price &&
            !isTinyDiscount(product, product.deeper_discount_price_excl_tax)
          "
          class="savings"
        >
          {{
            percentageDiscount(product, product.deeper_discount_price_excl_tax)
          }}% off
        </span>
      </li>
    </ul>
  </div>
</template>

<script>
import {
  calculatePercentageDiscount,
  isTinyDiscount,
} from '~/../common/utils/catalog'
import { itemSizeStr } from '~/utils/product'

export default {
  name: 'TieredDiscountList',
  components: {
    Price: () => import('~/../common/components/Price.vue'),
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  computed: {
    itemSizeStr() {
      return itemSizeStr(this.product)
    },
  },
  methods: {
    percentageDiscount(sr, price) {
      return calculatePercentageDiscount(sr, price)
    },
    isTinyDiscount,
  },
}
</script>
